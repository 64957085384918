import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BigpcService } from '../Services/bigpc.service';
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { MatDialog } from '@angular/material/dialog';
import { LangReplacerPipe } from 'app/lang-replacer.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { LocalstorgeService } from '../Services/localstorge.service';
@Component({
  selector: 'app-alarm-stagetwo',
  templateUrl: './alarm-stagetwo.component.html',
  styleUrls: ['./alarm-stagetwo.component.css']
})
export class AlarmStagetwoComponent implements OnInit {
  @Input() priceAlarm: FormGroup;
  @Input() pricesForm: FormGroup;
  @Input() Lieferort;
  @Input() labels;
  @Input() colors;
  @Input() theme: any;
  @Input() clickedPage: any;
  @Output() page1Event = new EventEmitter();
  @Output() page2Event = new EventEmitter();
  @Output() getpriceCalculatorEvent = new EventEmitter();
  @Output() pricedetailsEvent = new EventEmitter();
  @Output() steuerhinweisPopupEvent = new EventEmitter();
  @Output() specialtextpopup1Event = new EventEmitter<any>();

  @Input() showFullScreenLoading: boolean;
  @Input() languageData;
  @Input() viewsnack1;
  @Input() viewsnack2;
  @Input() minMaxData;
  @Input() volume;
  @Input() weight;
  @Input() volume_short;
  @Input() weight_short;
  @Input() currency_symbol;
  @Input() final_PC;
  @Input() noData;
  @Input() SecoendSatgeData: boolean;
  @Input() offer: any;
  langVariable: any;
  @Input() set _langVariable(langVariable) {
    this.langVariable = langVariable;

  };
  get _langVariable() {
    return this.langVariable;
  }

  // offer: any;
  menge: any;
  // @Input() set _offer(offer) {
  //   this.offer = offer;

  // };
  // get _offer() {
  //   return this.offer;
  // };


  @Input() localoptions;
  @Output() stageThreeEvent = new EventEmitter();
  private _window: ICustomWindow;
  hostname;
  label = "Liter";
  desiredPriceView: boolean = true;
  setpriceLimitView: boolean = true;
  pricetypeArr = [];
  @Input() isPriceFormValid: boolean;
  @Input() desiredPriceLimit;
  showError: boolean = false;
  showError1: boolean = false;

  unsubscribeSignal: Subject<void> = new Subject();
  subscriptions: Subscription[] = [];
  constructor(
    private BigpcService: BigpcService,
    private windowRef: WindowRefService,
    public dialog: MatDialog,
    private localStorage: LocalstorgeService) {
    this._window = this.windowRef.nativeWindow;

    this.subscriptions.push(this.BigpcService.updatePriceAlaram$.pipe(takeUntil(this.unsubscribeSignal.asObservable())).subscribe((event: any) => {
      // console.log(event.data, 7888);
      if (event.data) {
        if (event.data) {
          this.offer = event.data;
          this.noData = false;
          this.SecoendSatgeData && this.priceData();
        };
      };
    }));

    // this.BigpcService.updatePriceAlaram$.subscribe((event) => {
    //   console.log(event.data, 7888);
    // if (event.data) {
    //   this.offer = event.data;
    //   this.noData = false;
    // };
    // });
  };

  ngOnInit() {
    // console.log(this.offer, 107);
    this.menge = this.priceAlarm.value.menge;
    this.hostname = this._window.location.hostname;
    this.getpriceCalculator();
    this.validatePriceform();
    this.validateFormChanges();
    this.SecoendSatgeData && this.priceData();
  };

  ngOnDestroy(): void {
    // console.log(this.subscriptions, 111);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  };

  validateFormChanges() {
    this.pricesForm.valueChanges.subscribe(form => {
      if (form.desiredPriceType && form.desiredPriceLimit && this.pricesForm.controls['desiredPriceLimit'].touched) {
        let desiredPriceLimit = form['desiredPriceLimit'];
        if (desiredPriceLimit.includes(",")) {
          desiredPriceLimit = desiredPriceLimit.replace('.', '');
        }

        desiredPriceLimit = desiredPriceLimit.replace(',', '.');
        if (parseFloat(this.offer[form.desiredPriceType]) < desiredPriceLimit) {
          this.pricesForm.controls['desiredPriceLimit'].setErrors({ "error": "desired price should be less" })
        } else {
          this.pricesForm.controls['desiredPriceLimit'].setErrors(null);
        };
      };

      if (form.priceLimitType && form.priceLimit && this.pricesForm.controls['priceLimit'].touched) {
        let priceLimit = form['priceLimit'];
        if (priceLimit.includes(",")) {
          priceLimit = priceLimit.replace('.', '');
        };

        priceLimit = priceLimit.replace(',', '.');

        if (parseFloat(this.offer[form.priceLimitType]) > priceLimit) {
          this.pricesForm.controls['priceLimit'].setErrors({ "error": "price limit should be greater" })
        } else {
          this.pricesForm.controls['priceLimit'].setErrors(null);
        };
      };
    })
  };

  priceData() {
    this.pricetypeArr = [
      { name: this.languageData['Bestelloptionen']['Var_price_per'] + ' ' + this.offer?.priceFactor + this.offer?.units?.symbol + ' ' + this.languageData['Bestelloptionen']['Var_incl_vat'], value: 'grossPriceFactor' },
      { name: this.languageData['Bestelloptionen']['Var_price_per'] + ' ' + this.offer?.priceFactor + this.offer?.units?.symbol + ' ' + this.languageData['Bestelloptionen']['Var_excl_vat'], value: 'basicSurgargePriceFactorNett' },
      { name: this.languageData['Bestelloptionen']['Var_overall_price'] + ' ' + this.menge + ' ' + this.offer?.units?.symbol + ' ' + this.languageData['Bestelloptionen']['Var_incl_vat'], value: 'gross' },
      { name: this.languageData['Bestelloptionen']['Var_overall_price'] + ' ' + this.menge + ' ' + this.offer?.units?.symbol + ' ' + this.languageData['Bestelloptionen']['Var_excl_vat'], value: 'basicSurgargePriceNett' },
    ];

    if (this.pricesForm.value.desiredPriceType && this.pricesForm.value.priceLimitType) {
      this.pricesForm['controls']['desiredPriceType'].setValue(this.pricesForm.value.desiredPriceType);
      this.pricesForm['controls']['priceLimitType'].setValue(this.pricesForm.value.priceLimitType);
    } else {
      this.pricesForm['controls']['desiredPriceType'].setValue(this.pricetypeArr[0].value);
      this.pricesForm['controls']['priceLimitType'].setValue(this.pricetypeArr[0].value);
    };

    let alarmLocalData = this.localStorage.getAlarmData();

    // console.log(alarmLocalData.desiredPriceLimit)
    if (alarmLocalData.desiredPriceLimit) {
      this.compareAndValidatePrices(alarmLocalData.desiredPriceType, alarmLocalData.desiredPriceLimit.replace(",", '.'), true, false);
    };

    // console.log(alarmLocalData.priceLimit)
    if (alarmLocalData.priceLimit) {
      this.compareAndValidatePrices(alarmLocalData.priceLimitType, alarmLocalData.priceLimit.replace(",", '.'), false, true);
    }
  };

  validatePriceform() {
    if (this.pricesForm.controls['desiredPriceType'].valid && this.pricesForm.controls['desiredPriceLimit'].valid) {
      this.desiredPriceView = false;
    } else {
      this.desiredPriceView = true;
    };

    if (this.pricesForm.controls['priceLimitType'].valid && this.pricesForm.controls['priceLimit'].valid) {
      this.setpriceLimitView = false;
    } else {
      this.setpriceLimitView = true;
    };
  };

  showInput(inputSec) {
    this[inputSec] = !this[inputSec];
    this.priceData()
  };

  desiredPriceType(event) {
    this.pricesForm['controls']['desiredPriceType'].setValue(event);
    this.compareAndValidatePrices(event, this.pricesForm.controls['desiredPriceLimit'].value.replace(",", '.'), true, false);
  };

  priceLimitType(event) {
    this.pricesForm['controls']['priceLimitType'].setValue(event);
    this.compareAndValidatePrices(event, this.pricesForm.controls['priceLimit'].value.replace(",", '.'), false, true);
  };

  pricedetails(offer) {
    this.pricedetailsEvent.emit(offer);
  };

  getpriceCalculator() {
    this.getpriceCalculatorEvent.emit();
  };

  specialtextpopup1(event) {
    this.specialtextpopup1Event.emit(event)
  };

  steuerhinweisPopup() {
    this.steuerhinweisPopupEvent.emit();
  };

  Page1() {
    this.page1Event.emit();
  };

  page2() {
    this.page2Event.emit();
  };

  submitValue() {
    if (!this.viewsnack2 && !this.viewsnack1) {
      this.stageThreeEvent.emit();
    };
  };

  desiredPriceLimitVal(event) {
    if (event.includes(",")) {
      event = event.replace('.', '');
    }
    let value = event;
    let replacedPipe = new LangReplacerPipe();
    let divider = replacedPipe.transform(event, this.langVariable);
    this.pricesForm.controls['desiredPriceLimit'].setValue(divider);
    if (value.length >= 4) {
      divider = value
    } else {
      divider = divider.replace(",", '.')
    }
    this.compareAndValidatePrices(this.pricesForm.controls['priceLimitType'].value, divider, true, false)
  };

  priceLimitVal(event) {
    if (event.includes(",")) {
      event = event.replace('.', '');
    }
    let value = event;
    let replacedPipe = new LangReplacerPipe();
    let divider = replacedPipe.transform(event, this.langVariable);
    this.pricesForm.controls['priceLimit'].setValue(divider);
    if (value.length >= 4) {
      divider = value
    } else {
      divider = divider.replace(",", '.')
    }
    this.compareAndValidatePrices(this.pricesForm.controls['priceLimitType'].value, divider, false, true)
  };

  compareAndValidatePrices(value, amount, low, high) {
    // console.log(value, amount, low, high, this.offer?.grossPriceFactor, 2666)
    let price = amount;
    switch (value) {
      case 'grossPriceFactor':
        if (low) {
          this.viewsnack1 = this.offer?.grossPriceFactor < price ? true : false;
        } else if (high) {
          this.viewsnack2 = this.offer?.grossPriceFactor > price ? true : false;
        };
        // if (low) {
        //   this.offer.grossPriceFactor < price ? this.viewsnack1 = true : this.viewsnack1 = false;
        // } else if (high) {
        //   this.offer.grossPriceFactor > price ? this.viewsnack2 = true : this.viewsnack2 = false;
        // };
        break;
      case 'basicSurgargePriceFactorNett':
        if (low) {
          this.viewsnack1 = this.offer?.basicSurgargePriceFactorNett < price ? true : false;
        } else if (high) {
          this.viewsnack2 = this.offer?.basicSurgargePriceFactorNett > price ? true : false;
        };
        // if (low) {
        //   this.offer.basicSurgargePriceFactorNett < price ? this.viewsnack1 = true : this.viewsnack1 = false;
        // } else if (high) {
        //   this.offer.basicSurgargePriceFactorNett > price ? this.viewsnack2 = true : this.viewsnack2 = false;
        // };
        break;
      case 'gross':
        let price1 = parseFloat(amount).toFixed(3);
        price1 = price1.replace(".", '');
        if (low) {
          this.viewsnack1 = this.offer?.gross < price1 ? true : false;
        } else if (high) {
          this.viewsnack2 = this.offer?.gross > price1 ? true : false;
        }
        // if (low) {
        //   this.offer.gross < price1 ? this.viewsnack1 = true : this.viewsnack1 = false;
        // } else if (high) {
        //   this.offer.gross > price1 ? this.viewsnack2 = true : this.viewsnack2 = false;
        // }
        break;
      case 'basicSurgargePriceNett':
        let price2 = parseFloat(amount).toFixed(3);
        price2 = price2.replace(".", '');
        if (low) {
          this.viewsnack1 = this.offer?.basicSurgargePriceNett < price2 ? true : false;
        } else if (high) {
          this.viewsnack2 = this.offer?.basicSurgargePriceNett > price2 ? true : false;
        }
        // if (low) {
        //   this.offer.basicSurgargePriceNett < price2 ? this.viewsnack1 = true : this.viewsnack1 = false;
        // } else if (high) {
        //   this.offer.basicSurgargePriceNett > price2 ? this.viewsnack2 = true : this.viewsnack2 = false;
        // }
        break;
    };

    // console.log(this.viewsnack1, this.viewsnack2, 3033);
  };
};

