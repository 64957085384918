<div class="aupris_main scrolltotopprogress" [ngClass]="theme == 'Theme1' ? 'theme1':'theme2'">
    <div class="aurow " [ngClass]="theme == 'Theme1' ? 'alignment1':'alignment'"
        *ngIf="clickedPage=='Page2' && !noData">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
            <p>
                {{languageData['Bestelloptionen'].Var_latestprice || 'Unser aktueller Preis'}}:
            </p>
        </div>

        <div class="aucol-lg-12 aucol-md-12 pull-right aucol-sm-12 aucol-xs-12">

            <div class="panel-body-aupris panel panel-default boxShadow" style="padding: 0px 0px 0px 10px;">
                <div class="aurow row-eq-height"
                    style="margin-bottom: 0px;margin-right: -15px!important; margin-left: -15px !important;">
                    <div class="'aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12"
                        style="padding:5px;font-size:12px; padding-left: 15px;margin-top: 10px;margin-bottom: 10px;">
                        <div>
                            <h4 class="product">
                                <b>{{offer?.product?.label}}</b>
                                <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="offer?.product?.description"
                                    (click)="specialtextpopup1(offer?.product?.description)">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 438.533 438.533" style="enable-background:new 0 0 438.533 438.533;"
                                        [ngStyle]="{fill: 'var(--primaryColour)'}" xml:space="preserve">
                                        <g>
                                            <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
                                c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
                                c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
                                s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
                                C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
                                c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
                                c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
                                c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
                                H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
                                c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
                                v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
                                c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
                                H292.359z" />
                                        </g>
                                    </svg>
                                </span>
                            </h4>
                        </div>

                        <div class="aurow aucol-md-12">
                            <div class="aucol-md-6 aucol-xs-6 aucol-sm-6 aucol-lg-6 auprisfont14"
                                style="padding-left:1px;">
                                {{languageData['Bestelloptionen'].Var_Preis_pro || 'Preis pro'}}
                                {{offer?.priceFactor}}
                                {{offer?.units?.name}}
                                <b class="auprisfont14 inline-block"><span [innerHTML]="offer?.currencySymbol"></span>
                                    {{((offer?.pc100lWithoutFlatRate && offer?.identifyPackagesInPc) ?
                                    (offer?.basicPricePriceFactorNett) :
                                    (offer?.basicSurgargePriceFactorNett)) | langReplacer : langVariable}}
                                    {{(offer?.pc100lWithoutFlatRate && offer?.identifyPackagesInPc) ? '' : '*'}}
                                </b>

                                {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
                                <br>
                                <small>
                                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                                    {{offer?.productTax?.status ? '**' : '' }}
                                </small>
                                <span class="auprisfont25 inline-block">
                                    <b>
                                        <span [innerHTML]="offer?.currencySymbol"></span>
                                        {{offer?.grossPriceFactor | langReplacer : langVariable}}
                                        {{(offer?.pc100lWithoutFlatRate && offer?.identifyPackagesInPc) ? '' : '*'}}
                                    </b>
                                </span>

                                <span class="dblock pointer" *ngIf="offer?.productTax?.status"
                                    (click)="steuerhinweisPopup(offer.productTax.infoText)">
                                    <span class="Steualign">
                                        **
                                        {{ languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis' }}
                                    </span>
                                </span>
                            </div>
                            <div class="aucol-md-6 aucol-xs-6 aucol-sm-6 aucol-lg-6 auprisfont14"
                                style="border-left: 1px solid rgba(0, 0, 0, 0.2); margin-left: -12px">
                                {{languageData['Bestelloptionen'].Var_Gesamtpreis || 'Gesamtpreis'}}
                                <b class="auprisfont14 inline-block"><span [innerHTML]="offer?.currencySymbol"></span>
                                    {{
                                    (offer?.basicSurgargePriceNett)
                                    |
                                    langReplacer : langVariable
                                    }}
                                    <sup>{{(offer?.surcharge?.status) ? '*' : ''}}</sup>
                                    netto
                                </b>

                                <br>
                                <small>
                                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                                    {{offer?.productTax?.status ? '**' : '' }}
                                </small>

                                <span class="auprisfont25 inline-block">
                                    <b>
                                        <span [innerHTML]="offer?.currencySymbol"></span>
                                        {{offer?.gross | langReplacer : langVariable}}
                                        <sup>{{(offer?.surcharge?.status) ? '*' : ''}}</sup>
                                    </b>
                                </span>

                                <span (click)="pricedetails(offer)" style="cursor: pointer;">
                                    <svg style="padding-left: 3px; position: absolute;margin-top: 10px"
                                        *ngIf="offer?.showCustomers" xmlns="http://www.w3.org/2000/svg" width="18px"
                                        height="18px" viewBox="0 0 448 512">
                                        <a>
                                            <path attr.fill="{{'var(--primaryColour)'}}"
                                                d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z" />
                                        </a>
                                    </svg>

                                </span>
                                <div>
                                    <small *ngIf="offer?.surcharge?.name">
                                        {{offer?.surcharge?.name ? '* inkl' : ''}}
                                        {{offer?.surcharge?.name}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mt25">
            <div class="aurow" [formGroup]="pricesForm">

                <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12 desiredPriceSec" *ngIf="desiredPriceView">
                    <p>
                        {{languageData['Bestelloptionen'].Var_desiredprice || 'Wunschpreis'}}
                    </p>
                    
                    <p>
                        {{languageData['Bestelloptionen'].Var_pricedrop || 'iese Funktion informiert Sie, wenn die
                        Preise fallen und Ihr gewünschtes Preisniveau erreichen.'}}
                    </p>

                    <button type="button" class="font16 largeBtn" style="border:none"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        [style.background]="'var(--primaryColour)'" (click)="showInput('desiredPriceView')">
                        {{languageData['Bestelloptionen'].Var_setprice || 'Wunschpreis einstellen'}}
                    </button>
                </div>

                <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12 desiredPriceInputSec"
                    *ngIf="!desiredPriceView">
                    <p>
                        {{languageData['Bestelloptionen'].Var_desiredprice || 'Wunschpreis'}}
                    </p>
                    <p style="margin-bottom: 20px;">
                        {{languageData['Bestelloptionen'].Var_pricedrop || 'iese Funktion informiert Sie, wenn die
                        Preise fallen und Ihr gewünschtes Preisniveau erreichen.'}}
                    </p>
                    <div class="aurow">
                        <div class="aucol-lg-7 aucol-md-7 aucol-sm-7 aucol-xs-7 field" style="padding-right: 0px">
                            <label class="posAbs aupLabel">
                                {{languageData['Bestelloptionen'].Var_desiredprice || 'Wunschpreis'}}
                            </label>
                            <select name="product" class="auform-control forLabel"
                                (change)="desiredPriceType($event.target.value);" formControlName="desiredPriceType"
                                style="padding:6px 0px;font-size: 12px;">
                                <option *ngFor="let price of pricetypeArr" [value]="price.value">
                                    {{price.name }}
                                </option>
                            </select>
                        </div>
                        <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-5 al">
                            <div class="field" style="display: inline-flex;">
                                <span [innerHTML]="offer?.currencySymbol"
                                    style="font-size: 20px;margin: 4px 11px 0px 0px;"></span>
                                <div>
                                    <input class="auform-control" (blur)="desiredPriceLimitVal($event.target.value)"
                                        formControlName="desiredPriceLimit" appOnlyNumberWithDot>
                                        <!-- {{viewsnack1}} -->
                                    <div class="alert-danger" *ngIf="viewsnack1">
                                        {{languageData['Bestelloptionen'].Var_WunschpreisError || 'Preis muss kleiner
                                        als aktueller Preis sein.'}}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12  priceLimitSec" *ngIf="setpriceLimitView">
                    <p>
                        {{languageData['Bestelloptionen'].Var_pricelimit || 'preisalarm'}}
                    </p>
                    <p>
                        {{languageData['Bestelloptionen'].Var_pricerise || 'Diese Funktion informiert Sie, wenn die
                        Preise steigen und Ihr festgelegtes Preislimit erreichen.'}}
                    </p>

                    <button type="button" class="font16 largeBtn" style="border:none"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        [style.background]="'var(--primaryColour)'" (click)="showInput('setpriceLimitView')">
                        {{languageData['Bestelloptionen'].Var_setlimit || 'Preisalarm einstellen'}}

                    </button>
                </div>
                <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12  priceLimitInputSec"
                    *ngIf="!setpriceLimitView">
                    <p>
                        {{languageData['Bestelloptionen'].Var_pricelimit || 'preisalarm'}}
                    </p>
                    <p style="margin-bottom: 20px;">
                        {{languageData['Bestelloptionen'].Var_pricerise || 'Diese Funktion informiert Sie, wenn die
                        Preise steigen und Ihr festgelegtes Preislimit erreichen.'}}
                    </p>

                    <div class="aurow">
                        <div class="aucol-lg-7 aucol-md-7 aucol-sm-7 aucol-xs-7 field" style="padding-right: 0px;">
                            <label class="posAbs aupLabel">
                                {{languageData['Bestelloptionen'].Var_pricelimit || 'Preisalarm'}}
                            </label>
                            <select name="product" class="auform-control forLabel"
                                (change)="priceLimitType($event.target.value)" formControlName="priceLimitType"
                                style="padding:6px 0px;font-size: 12px;">
                                <option *ngFor="let price of pricetypeArr" [value]="price.value">
                                    {{price.name }}
                                </option>
                            </select>
                        </div>
                        <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-5 al">
                            <div class="field" style="display: inline-flex;">
                                <span [innerHTML]="offer?.currencySymbol"
                                    style="font-size: 20px;margin: 4px 11px 0px 0px;"></span>
                                <div>
                                    <input class="auform-control" (blur)="priceLimitVal($event.target.value)"
                                        formControlName="priceLimit" appOnlyNumberWithDot>
                                        <!-- {{viewsnack2}} -->
                                    <div class="alert-danger" *ngIf="viewsnack2">
                                        {{languageData['Bestelloptionen'].Var_PreisalarmError || 'Preis muss größer als
                                        aktueller Preis sein.'}}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="noData" style="text-align: center;margin-top: 22%;margin-bottom: 24%;">
        {{languageData['Bestelloptionen'].Var_alarmError || ' Die ausgewählte Qualität ist an Ihrem Lieferort nicht
        verfügbar. Bitte wählen Sie eine andere Qualität aus.'}}
    </div>

    <div class="aurow pb20 " [ngClass]="theme == 'Theme1' ? 'buttonalignment1':'buttonalignment'">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
            <button type="button" class="cnlBtn font16 smalBtn"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="Page1()">
                {{languageData['Adresseingabe'].Var_Zuruck || 'Zurück'}}
            </button>
            <button type="button" class="nxtBtn font16 pull-right smalBtn" *ngIf="!noData"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                [style.background]="'var(--primaryColour)'" (click)="submitValue()">
                {{languageData['Adresseingabe'].Var_Weiter || 'Weiter'}}
            </button>
        </div>
    </div>

    <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
</div>